import React from "react";

function Footer() {

    return (
        <>
            <footer className="site-footer ">
                <div className="footer-main">
                    <div className="footer-in">

                        <div className="footer-right">
                            <ul>
                                <li><a href="https://aiduals.com/terms.html" target="_blank">Terms & Conditions</a></li>
                                <li><a href="https://aiduals.com/privacy.html" target="_blank">Privacy Policy</a></li>
                                <li><a href="http://support.vineasx.com/" target="_blank">Support</a></li>
                            </ul>
                        </div>
                        <div className="footer-left">
                            <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank" rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank" rel="noreferrer">Vega6</a></p>
                            <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                        </div>
                        <div className='footer-logo'><a href="https://reelapps.io/" target="_blank"><img alt="" src={require('../../assets/images/reelapps.png')} /></a></div>

                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;